const auth = {
    // general features
    authentication: null,
    get_authentication: function() {
            if (!this.authentication) {
                let sessionAuthentication = sessionStorage.getItem('authentication');
                sessionAuthentication && (this.authentication = JSON.parse(sessionAuthentication));
            }
            return this.authentication;
        },
    clear: function() { this.authentication = null; },
    get is_loggedin() { return !!this.get_authentication(); },

    // user attributes
    get id() { return get_property(this, 'id'); },
    get individual_type() { return get_property(this, 'individual_type'); },
    get organization_id() { return get_property(this, 'organization_id'); },
    get organization() { return get_property(this, 'organization'); },
    get actingorganization_id() { return get_property(this, 'actingorganization_id'); },
    get firstname() { return get_property(this, 'firstname'); },
    get lastname() { return get_property(this, 'lastname'); },
    get email() { return get_property(this, 'email'); },

    // org components
    get org_host() { return get_flag(this, 'org_host'); },

    // access
    has_access: function(module, access) { return check_access(this, module, access); },

    // constants
    constants: {
        permission_ids: {
            THSuperadmin: '0635A3DB-9214-4468-8582-4978C2FD3673',
            Superadmin: '8BC291D7-C763-441C-B488-45935BFAD1D2'
        },
        org_ids: {
            ABCRC: 'D95FA1D8-9225-4F46-BF02-6D4DD236A1B6'
        }
    },
};


//#region internal functions

const get_property = (auth, property_name) => {
    let authentication = auth.get_authentication();
    return authentication && authentication[property_name];
}

const get_flag = (auth, flag_name) => {
    let authentication = auth.get_authentication();
    return authentication ? authentication[flag_name] === 'YES' : false;
}

const check_access = (auth, module, module_access) => {
    let authentication = auth.get_authentication();
    if (!authentication) return false;
    if (authentication.permission_id === auth.constants.permission_ids.THSuperadmin) return true;
    return authentication.access.some(item => item.module === module && JSON.parse(item.module_access).includes(module_access));
}

//#endregion

export default auth;