import React, { useState, useEffect, useCallback } from 'react';
import { Input, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import _ from 'lodash';

import './cmp_search.css';

export default function ({ refresh, var_filter, set_filter, table_config }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_search_term, set_search_term ] = useState(var_filter.search_term);
    const delayed_search = useCallback(_.throttle(execute_search, 1000), []);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_search_term('');
    }, [refresh]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function execute_search(value){
        const new_filter = {
            ...var_filter,
            search_term: value.trim().length > 0 ? value.trim() : null,
            search_fields: table_config.reduce((acc, item) => item.filter && item.datatype === 'text' ? acc.concat(item.name) : acc, []),
            updated: true
        };
        set_filter(new_filter);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_search(e, { value }){
        set_search_term(value);
        delayed_search(value);
    }

    // RENDER ==========================================================================================================

    return (table_config && table_config.some(item => item.filter && item.datatype === 'text')) ?
    (
        <div className='tablecontrols_search_input'>
            <Form.Field
                name='search'
                value={var_search_term || ''}
                onChange={onChange_search}
                control={Input}
                placeholder={t('Search...')}
            />
        </div>
    ) : null;
}