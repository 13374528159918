/* eslint-disable import/no-anonymous-default-export */
export default {
    s3: {
        REGION: "us-east-1",
        BUCKET: "terrahub-resources"
    },
    apiGateway_abcrc: {
        REGION: "ca-central-1",
        URL: "https://wn0hknf039.execute-api.ca-central-1.amazonaws.com/prod"
    },
    apiGateway_abcrc_app: {
        REGION: "ca-central-1",
        URL: "https://275qsq98n2.execute-api.ca-central-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "ca-central-1",
        USER_POOL_ID: "ca-central-1_53Ll2hMyD",
        APP_CLIENT_ID: "53gneo7v6eot0cu84oh1l6ol9m",
        IDENTITY_POOL_ID: "ca-central-1:81c1dec6-c8b7-486f-8a46-ad1c6f6ca320"
    },
    apiGateway_translation: {
        REGION: "us-east-1",
        URL: "https://f8xyktx3i8.execute-api.us-east-1.amazonaws.com/dev"
    },
    language: {
        en: {
            name: "English",
            i18n: "en-US",
            moment: "en-ca",
            datepicker: "en-US"
        },
        fr: {
            name: "French",
            i18n: "fr-CA",
            moment: "fr-ca",
            datepicker: "fr-FR"
        }
    }
};
