/* eslint-disable import/no-anonymous-default-export */
import { Table, Button } from 'semantic-ui-react'
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import React from "react";
import Moment from "moment";

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import CMP_TABLECONTROLS, { filter_helper } from "../../../components/cmp_tablecontrols/cmp_tablecontrols";

import './crd_transactions.css';



export default function(appProps){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    const [ var_history, set_history ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_limit, set_limit] = useState(100);
    const [ var_offset, set_offset] = useState(0);
    const [ var_search, set_search ] = useState('');
    const [ var_sortby, set_sortby ] = useState('epochtransactiondate');
    const [ var_sortorder, set_sortorder ] = useState('descending');
    const [ var_filter, set_filter ] = useState(filter_helper.initialize(true));



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        set_filter(filter_helper.initialize(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if (var_filter.load){
            populate_history(null,0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_filter])
    



    //  async functions ------------------------------------------------------------------------------------------------
    async function populate_history(limit,offset,sortby,sortorder,search,filter){

        set_loading(true);

        if(limit) {
            set_limit(limit);
        }else{
            limit=var_limit
        }

        if(offset || offset === 0) {
            set_offset(offset);
        }else{
            offset=var_offset
        }

        if(sortby) {
            set_sortby(sortby);
        }else{
            sortby=var_sortby
        }

        if(sortorder) {
            set_sortorder(sortorder);
        }else{
            sortorder=var_sortorder
        }

        if(search) {
            if(search === '-') {
                search = '';
            }
            set_search(search);
        }else{
            search=var_search
        }

        filter = filter ? filter : var_filter;

        try {
            let results = await API_get_transactions(limit,offset,sortby,sortorder,search,filter);
            set_totalrows(results.totalrows);
            set_history(results.results === undefined ? [] : results.results);
        } catch (e) {
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername,sortorder){
        try {
            return (await API_get_transactions(filter_helper.maxFilterItems, 0, filtername, sortorder, var_search,{}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_history(limit, offset){
        try {
            return (await API_get_transactions(limit, offset, var_sortby, var_sortorder, var_search, var_filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
     }

    async function scanBag(generalobject, doorNo, trailerNo) {
        try {
            let results = await API_scan_bag(generalobject, doorNo, trailerNo);
            window.alert('Retry succeeded.');
        } catch (e) {
            console.log(e);
            window.alert('Retry failed.');
        }
    }

    async function setBagStatus(generalobject, doorNo, trailerNo, userid, rbill_id) {
        try {
            let results = await API_set_bag_status(generalobject, doorNo, trailerNo, userid, rbill_id);
            window.alert('Retry succeeded.');
        } catch (e) {
            console.log(e);
            window.alert('Retry failed.');
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_transactions(limit,offset,sortby,sortorder,search,filter,filtername){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
         return API.post('abcrc-admin-api', '/get-transactions/' + JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id,
        {queryStringParameters: {
            limit: limit,
            offset: offset,
            sortby: sortby,
            sortorder: sortorder,
            search:search,
            tz: Moment().format('Z'),
            filtername: filtername
        },
        body: filter
        }
        );
    }

    function API_scan_bag(generalobject, doorNo, trailerNo){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        console.log(JSON.parse(generalobject).generalObject.serial)
        return API.post('abcrc-app-api',
            '/scan-bag',
            {
                body: {
                    doorNo: doorNo,
                    trailerNumber: trailerNo,
                    bagserial: JSON.parse(generalobject).generalObject.serial,
                    bagtag: JSON.parse(generalobject).generalObject.tag,
                    bagTagCorrected: JSON.parse(generalobject).generalObject.tagCorrected,
                    bagContent: JSON.parse(generalobject).generalObject.content
                }
            }
        );
    }

    function API_set_bag_status(generalobject, doorNo, trailerNo, userid, rbill_id){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('abcrc-app-api',
            '/set-bag-status',
            {
                body: {
                    rbillID: rbill_id,
                    bagtag: JSON.parse(generalobject).generalObject.tag,
                    bagtag_corrected: JSON.parse(generalobject).generalObject.tagCorrected,
                    bagcontent: JSON.parse(generalobject).generalObject.content,
                    bagserial: JSON.parse(generalobject).generalObject.serial,
                    bagquantity: JSON.parse(generalobject).generalObject.quantity,
                    pallet: JSON.parse(generalobject).generalObject.pallet,
                    status: JSON.parse(generalobject).generalObject.status,
                    userid: userid,
                    bagDamaged: JSON.parse(generalobject).generalObject.damaged,
                    doorNo: doorNo,
                    trailerNumber: trailerNo
                }
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sort(sortby){
        var sortorder = var_sortorder;
        if (var_sortby !== sortby) {
            sortorder = 'ascending';
        }else {
            if(sortorder === 'ascending'){
                sortorder = 'descending';
            }else if(sortorder === 'descending'){
                sortorder = 'ascending';
            }
        }
        set_sortorder(sortorder);
        populate_history('',0,sortby,sortorder)
    }

    function onClick_resend_transaction(description, generalobject, doorNo, trailerNo, userid, rbill_id) {
        if (description.toLowerCase().startsWith('bag scan failed')) {
            scanBag(generalobject, doorNo, trailerNo);
        } else if (description.toLowerCase().startsWith('bag status set failed')) {
            setBagStatus(generalobject, doorNo, trailerNo, userid, rbill_id);
        } else {
            window.alert('Retry failed.');
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="content-card-noheader" id="tab_indv_wallet_history">
                <div className="content-card-gridcontent">
                    <div className="content-card-header mobile_tab_name">
                        {t('History')}
                    </div>
                    <div className="content-filter">
                        <div className="content-filter-item item-pagination">
                            <CMP_TABLECONTROLS
                                    var_limit={var_limit} var_offset={var_offset} var_ready={var_ready} var_loading={var_loading}
                                    total_rows={var_history && var_history.length > 0 ? var_totalrows : 0} populatefunction={populate_history}
                                    downloadname='Transaction History' downloadfunction={download_history}
                                    var_filter={var_filter} set_filter={set_filter} populatefilterfunction={populate_filters}
                                    table_config={[
                                        {name: 'status', datatype: 'text', labelKey: 'STATUS', download: true, filter: true},
                                        {name: 'description', datatype: 'text', labelKey: 'DESCRIPTION', download: true, filter: true},
                                        {name: 'rbill_id', datatype: 'text', labelKey: 'RBILL ID', download: true, filter: true},
                                        {name: 'userid', datatype: 'text', labelKey: 'USER ID', download: true, filter: true},
                                        {name: 'epochtransactiondate', datatype: 'date', labelKey: 'TRANSACTION DATE', download: false, filter: true},
                                        {name: 'door_number', datatype: 'text', labelKey: 'DOOR NUMBER', download: true, filter: true},
                                        {name: 'trailer_number', datatype: 'text', labelKey: 'TRAILER NUMBER', download: true, filter: true},
                                        {name: 'bagtag', datatype: 'text', labelKey: 'BAG TAG', download: true, filter: true},
                                        {name: 'bagserial', datatype: 'text', labelKey: 'BAG SERIAL', download: true, filter: true},
                                        {name: 'transactionid', datatype: 'text', labelKey: 'TRANSACTION ID', download: true, filter: true}
                                    ]}
                            />
                        </div>
                     </div>
                    <div className="tablewrapper">
                        <Table unstackable sortable compact className={(var_loading ? " tbl_loading" : "")}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'status' ? var_sortorder : null} onClick={()=>onClick_sort('status')}>{t('STATUS')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'description' ? var_sortorder : null} onClick={()=>onClick_sort('description')}>{t('DESCRIPTION')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'rbill_id' ? var_sortorder : null} onClick={()=>onClick_sort('rbill_id')}>{t('RBILL ID')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'epochtransactiondate' ? var_sortorder : null} onClick={()=>onClick_sort('epochtransactiondate')}>{t('TRANSACTION DATE')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_transactionby" sorted={var_sortby === 'userid' ? var_sortorder : null} onClick={()=>onClick_sort('userid')}>{t('USER ID')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'door_number' ? var_sortorder : null} onClick={()=>onClick_sort('door_number')}>{t('DOOR NR')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'trailer_number' ? var_sortorder : null} onClick={()=>onClick_sort('trailer_number')}>{t('TRAILER NR')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'bagtag' ? var_sortorder : null} onClick={()=>onClick_sort('bagtag')}>{t('BAG TAG')}</Table.HeaderCell>
                                    <Table.HeaderCell sorted={var_sortby === 'bagserial' ? var_sortorder : null} onClick={()=>onClick_sort('bagserial')}>{t('BAG SERIAL')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_transactionby" sorted={var_sortby === 'generalobject' ? var_sortorder : null}>{t('TRANSACTION DETAILS')}</Table.HeaderCell>
                                    <Table.HeaderCell className="td_transactionid" sorted={var_sortby === 'transactionid' ? var_sortorder : null} onClick={()=>onClick_sort('transactionid')}>{t('TRANSACTION ID')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {var_ready && var_totalrows !== 0 &&
                            <Table.Body>
                                {var_history.map((item,i) =>
                                    <Table.Row key={i}>
                                        <Table.HeaderCell>
                                            {item.status === 'FAILED' &&
                                                <Button className="btn_primary btn_active" onClick={() => onClick_resend_transaction(item.description, item.generalobject, item.door_number, item.trailer_number, item.userid, item.rbill_id)}>{t('RETRY')}</Button>
                                            }
                                        </Table.HeaderCell>
                                        <Table.Cell >{item.status}</Table.Cell>
                                        <Table.Cell className="td_description">{item.description}</Table.Cell>
                                        <Table.Cell >{item.rbill_id}</Table.Cell>
                                        <Table.Cell >{item.transactiondate}</Table.Cell>
                                        <Table.Cell >{item.userid}</Table.Cell>
                                        <Table.Cell >{item.door_number}</Table.Cell>
                                        <Table.Cell >{item.trailer_number}</Table.Cell>
                                        <Table.Cell >{item.bagtag}</Table.Cell>
                                        <Table.Cell >{item.bagserial}</Table.Cell>
                                        <Table.Cell className="td_generalobject">{item.generalobject}</Table.Cell>
                                        <Table.Cell className="td_transactionid">{item.transactionid}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            }
                        </Table>
                        {var_ready && var_totalrows === 0 &&
                        <div className="emptytable"><img src="/icons/alert 60px (e5e5e5).svg" alt={t('alert icon')} />{t('there are no history transactions to display')}</div>
                        }
                    </div>
                </div>
            </div>
        </>
    )

};