/* eslint-disable no-throw-literal */
/* eslint-disable import/no-anonymous-default-export */
import { API, Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Message, Icon } from 'semantic-ui-react'
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import config from "../../../config";
import '../../../i18n';

import './crd_newpassword.css';



export default function({ var_login, set_login, var_login_step, set_login_step, var_AWSuser, set_AWSuser, set_language }){

    //  TRIVIAL CHANGE
    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const history = useHistory();

    const [var_cognitoaccount, set_cognitoaccount] = useState([]);

    const [var_validatepassword_length, set_validatepassword_length] = useState(false);
    const [var_validatepassword_lowercase, set_validatepassword_lowercase] = useState(false);
    const [var_validatepassword_uppercase, set_validatepassword_uppercase] = useState(false);
    const [var_validatepassword_number, set_validatepassword_number] = useState(false);
    const [var_validatepassword_specialcharacter, set_validatepassword_specialcharacter] = useState(false);

    const [ var_processing, set_processing ] = useState(false);
    const [ var_ready, set_ready ] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [ var_error, set_error ] = useState(false);
    const [ var_passwordShown, set_passwordShown ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_validatepassword_length && var_validatepassword_lowercase && var_validatepassword_uppercase && var_validatepassword_number && var_validatepassword_specialcharacter) {
            set_ready(true)
        } else {
            set_ready(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_validatepassword_length, var_validatepassword_lowercase, var_validatepassword_uppercase, var_validatepassword_number, var_validatepassword_specialcharacter]);


    useEffect(() => {
        validatenewpassword();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_login.newpassword]);



    //  async functions ------------------------------------------------------------------------------------------------

    const asyncWait = ms => new Promise(resolve => setTimeout(resolve, ms));

    async function onClick_newpassword(){

        if(var_ready){

            set_error(false);
            set_processing(true);

            try {

                //  create new account
                let data = {
                    email: var_login.email,
                    password: var_login.newpassword,
                    firstname: var_login.firstname,
                    lastname: var_login.lastname,
                    phone: var_login.phone,
                    company: var_login.company,
                    invite_token: var_login.inviteid,
                };
                await API_post_cognitoaccount(data);

                //  sign in to new account
                let attempts = 0;
                while (true) {
                    try {
                        await Auth.signIn(var_login.email, var_login.newpassword);
                        break;
                    } catch {
                        attempts++;
                        if (attempts >= 5) {
                            throw 'Error in signIn';
                        }
                        await asyncWait(1000);
                    }
                }

                //  get cognito user details
                attempts = 0;
                let AWSuser;
                while (true) {
                    try {
                        AWSuser = await Auth.currentUserInfo();
                        break;
                    } catch {
                        attempts++;
                        if (attempts >= 5) {
                            throw 'Error in currentUserInfo';
                        }
                        await asyncWait(1000);
                    }
                }

                //  get TerraHub user details
                let result = await API_get_authentication();

                //check if account has been archived
                if (result === 'NO ACTIVE ACCOUNT'){
                    set_error(true);
                    set_processing(false);
                }

                let authentication = {};
                authentication['authenticated'] = true;
                authentication['id'] = result.id;
                authentication['individual_type'] = result.individual_type;
                authentication['actingorganization'] = result.organization;
                authentication['actingorganization_id'] = result.organization_id;
                authentication['actingpermission_id'] = result.permission_id;
                authentication['cognito_id'] = AWSuser.username;
                authentication['firstname'] = result.firstname;
                authentication['lastname'] = result.lastname;
                authentication['userid'] = result.userid;
                authentication['email'] = result.email;
                authentication['organization'] = result.organization;
                authentication['organization_id'] = result.organization_id;
                authentication['permission_id'] = result.permission_id;
                authentication['org_host'] = result.org_host;
                authentication['language'] = 'en';
                authentication['access'] = result.access;

                sessionStorage.setItem('authentication',JSON.stringify(authentication));

                history.push('/individuals')

            }catch (error) {
                set_error(true);
                set_processing(false);
            }

        }

    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_cognitoaccount(data){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('abcrc-admin-api', '/post-createaccountcognito',{ body: data });
    }

    function API_get_authentication(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('abcrc-admin-api', '/get-authentication');
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function validatenewpassword(){

        const cognitoaccount = {...var_cognitoaccount};
        cognitoaccount['email'] = var_login.email;
        cognitoaccount['password'] = var_login.newpassword;
        set_cognitoaccount(cognitoaccount);

        if(var_login.newpassword) {
            let password=var_login.newpassword;

            if (password.length >= 8) {
                set_validatepassword_length(true);
            } else {
                set_validatepassword_length(false);
            }

            if (/[a-z]/.test(password)) {
                set_validatepassword_lowercase(true);
            } else {
                set_validatepassword_lowercase(false);
            }

            if (/[A-Z]/.test(password)) {
                set_validatepassword_uppercase(true);
            } else {
                set_validatepassword_uppercase(false);
            }

            if (/[0-9]/.test(password)) {
                set_validatepassword_number(true);
            } else {
                set_validatepassword_number(false);
            }

            if (/[!@#$%^&*()_\-[\]{};':"\\|,.<>/?]/.test(password)) {
                set_validatepassword_specialcharacter(true);
            } else {
                set_validatepassword_specialcharacter(false);
            }
        }else{
            set_validatepassword_length(false);
            set_validatepassword_lowercase(false);
            set_validatepassword_uppercase(false);
            set_validatepassword_number(false);
            set_validatepassword_specialcharacter(false);
        }
    }

    function onChange_login_input(event){
        if(event.target.name==='email'){
            event.target.value=event.target.value.toLowerCase().trim()
        }
        if(event.target.name==='newpassword' ){
            event.target.value=event.target.value.trim()
        }
        const login = { ...var_login };
        login[event.target.name] = event.target.value;
        set_login(login);
    }

    function onClick_togglePassword() {
        set_passwordShown(var_passwordShown ? false : true);
    }


    // RENDER APP ======================================================================================================

    return (
        <div id="crd_newpassword">
            {var_login.inviteid &&
                <Message>
                    {t('Thank you for accepting the')} {var_login.name} {t('invite! Please create your account to complete the acceptance.')}
                </Message>
            }

            <Message>{t('Before logging in for the first time, you need to create a secure and unique password. Please submit your password below once all 5 password requirements have been met.')}</Message>

            <Form>
                <Form.Group>
                    <Form.Field
                        className='newpassword'
                        id='newpassword'
                        name='newpassword'
                        autoComplete='new-password'
                        value={var_login.newpassword || ''}
                        control={Input}
                        type={!var_passwordShown ? 'password' : 'text'}
                        onChange={onChange_login_input}
                        label={t('NEW PASSWORD')}
                        placeholder={t('New Password...')}
                        icon={<Icon name={!var_passwordShown ? 'eye' : 'eye slash'} link onClick={() => onClick_togglePassword()} />}
                    />
                </Form.Group>
                <div className="passwordvalidation">
                    <div className="passwordvalidation_item">
                        {var_validatepassword_length ?
                            <div className="passwordvalidation_item_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg" alt={t('password status')}/> </div>
                            :
                            <div className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (DB2430).svg" alt={t('password status')}/></div>
                        }
                        <div className="passwordvalidation_item_text">{t('8 characters long')}</div>
                    </div>
                    <div className="passwordvalidation_item">
                        {var_validatepassword_uppercase ?
                            <div className="passwordvalidation_item_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg" alt={t('password status')}/> </div>
                            :
                            <div className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (DB2430).svg" alt={t('password status')}/></div>
                        }
                        <div className="passwordvalidation_item_text">{t('upper case')}</div>
                    </div>
                    <div className="passwordvalidation_item">
                        {var_validatepassword_lowercase ?
                            <div className="passwordvalidation_item_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg" alt={t('password status')}/></div>
                            :
                            <div className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (DB2430).svg" alt={t('password status')}/></div>
                        }
                        <div className="passwordvalidation_item_text">{t('lower case')}</div>
                    </div>
                    <div className="passwordvalidation_item">
                        {var_validatepassword_number ?
                            <div className="passwordvalidation_item_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg" alt={t('password status')}/></div>
                            :
                            <div className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (DB2430).svg" alt={t('password status')}/></div>
                        }
                        <div className="passwordvalidation_item_text">{t('number')}</div>
                    </div>
                    <div className="passwordvalidation_item">
                        {var_validatepassword_specialcharacter ?
                            <div className="passwordvalidation_item_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg" alt={t('password status')}/> </div>
                            :
                            <div className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (DB2430).svg" alt={t('password status')}/></div>
                        }
                        <div className="passwordvalidation_item_text">{t('special character')}</div>
                    </div>
                </div>
            </Form>

            {!var_processing ?
                <Button id="btn_login" className={"btn_primary " + (var_ready ? 'btn_active' : '')} onClick={() => onClick_newpassword()}>{t('SUBMIT PASSWORD')}</Button>
                :
                <Button loading id="btn_login" className="btn_primary btn_active">{t('SUBMIT PASSWORD')}</Button>
            }

            <p className="toc">{t('By creating an account you are agreeing to these')} <a href={var_login.termsofservice} target="_blank" rel="noopener noreferrer">{t('Terms and Conditions')}</a></p>
        </div>
    )

}