/* eslint-disable import/no-anonymous-default-export */
import { Auth } from "aws-amplify";
import React, { useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import CRD_LOGIN from "./crd_login/crd_login";
import CRD_NEWPASSWORD from "./crd_newpassword/crd_newpassword";

import "./login.css";



export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const history = useHistory();

    const [ var_login, set_login ] = useState([]);
    const [ var_login_step, set_login_step ] = useState(null);
    const [ var_AWSuser, set_AWSuser ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect( ()=>{

        if (sessionStorage.getItem('authentication')){
            try{
                Auth.signOut();
            } catch {}
            sessionStorage.clear();
        }

        if(sessionStorage.getItem('authentication')){
            history.push('/individuals');
        }else{
            appProps.set_visibleframe(false);

            if(sessionStorage.getItem('invitestatus')){
                let invitestatus = JSON.parse(sessionStorage.getItem('invitestatus'));
                sessionStorage.removeItem('invitestatus');
                set_login({emaildisabled: true, ...invitestatus});
                sessionStorage.setItem('sso_invite', JSON.stringify({inviteid: invitestatus.inviteid}));
                if (invitestatus.invitestatus === 'NEW USER') {
                    set_login_step('NEWPASSWORD');
                } else {
                    set_login_step('LOGIN');
                }
            }else{
                set_login_step('LOGIN');
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(

        <div className="login_wrapper">
            <div className="login">
                {var_login_step === 'LOGIN' &&
                <CRD_LOGIN
                    var_login={var_login}
                    set_login={set_login}
                    set_login_step={set_login_step}
                    var_AWSuser={var_AWSuser}
                    set_AWSuser={set_AWSuser}
                    set_language={appProps.set_appDefaultLanguage}
                    var_params={appProps.match.params}>
                </CRD_LOGIN>
                }

                {var_login_step === 'NEWPASSWORD' &&
                <CRD_NEWPASSWORD
                    var_login={var_login}
                    set_login={set_login}
                    var_login_step={var_login_step}
                    set_login_step={set_login_step}
                    var_AWSuser={var_AWSuser}
                    set_AWSuser={set_AWSuser}
                    set_language={appProps.set_appDefaultLanguage}>
                </CRD_NEWPASSWORD>
                }

            </div>
        </div>
    )


};
