/* eslint-disable import/no-anonymous-default-export */
import { useEffect } from "react";
import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import './crd_resetvalidating.css';



export default function(){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------


    
    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>

            <div className="message info">
                <div className="message_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/alert 60px (42afb6).svg" alt={t('alert icon')}/></div>
                <div className="message_text_wrapper">
                    <div className="message_text resolution">{t('Validating password reset link')}</div>
                </div>
            </div>

        </>
    )

}