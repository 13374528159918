/* eslint-disable import/no-anonymous-default-export */
import {Button} from 'semantic-ui-react'
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../i18n';

import './cmp_confirmation.css';


export default function({ set_mdl_open, var_modaltitle, var_message, confirmation_function, var_reverse_buttons=false, var_call_to_function=false, cancel_function, success_function }){

// export default function({set_mdl_open, var_modaltitle, var_message, confirm_function }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_processing, set_processing ] = useState(false);
    const [ var_error, set_error ] = useState(false);
    const { t } = useTranslation();


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function call_confirmation_function(){
        set_processing(true);
        await confirmation_function().then(()=>{
            success_function ? success_function() : set_mdl_open(false);
        }).catch((e)=>{
            console.log(e);
            set_error(true);
            set_processing(false);
        });
    }


    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_no(){
        cancel_function ? cancel_function() : set_mdl_open(false);
    }

    function onClick_yes(){
        if(var_call_to_function){
            call_confirmation_function();
        } else {
            confirmation_function();
            success_function ? success_function() : set_mdl_open(false);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">{var_modaltitle}</div>
            <div className="modal-content">
                <p>{var_message}</p>
                {var_error &&
                <div id="confirmationerror" className="message warning">
                    <div className="message_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/warning 60px (d91e18).svg" alt={t('warning icon')} /></div>
                    <div className="message_text_wrapper">
                        <div className="message_text cause">{t('Something went wrong.')}</div>
                        <div className="message_text resolution">{t('Please check your APIs configuration.')}</div>
                    </div>
                </div>
                }
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    {var_reverse_buttons ?
                    <>
                        {!var_processing ?
                            <Button className="btn_secondary" onClick={() => onClick_yes()}>{t('YES')}</Button>
                            :
                            <Button loading className="btn_secondary">{t('YES')}</Button>
                        }
                        <Button className="btn_primary btn_active" onClick={() => onClick_no()}>{t('NO')}</Button>
                    </> :
                    <>
                        <Button className="btn_secondary" onClick={() => onClick_no()}>{t('NO')}</Button>
                        {!var_processing ?
                            <Button className="btn_primary btn_active" onClick={() => onClick_yes()}>{t('YES')}</Button>
                            :
                            <Button loading className="btn_primary btn_active">{t('YES')}</Button>
                        }
                    </>
                    }
                </div>
            </div>
        </>
    )
}