/* eslint-disable import/no-anonymous-default-export */
import { API, Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Icon, Modal, Message } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import config from '../../../config';

import './crd_login.css';



export default function ({ var_login, set_login, set_login_step, var_AWSuser, set_AWSuser, var_params }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const history = useHistory();

    const [var_processing, set_processing] = useState(false);
    const [var_ready, set_ready] = useState(false);
    const [var_error, set_error] = useState(false);
    const [var_passwordShown, set_passwordShown] = useState(false);
    const [var_authentication, set_authentication] = useState({});
    const [var_validationerror, set_validationerror] = useState(false);
    const [var_validationerrormessage, set_validationerrormessage] = useState('');

    const [ var_mdl_select_lang_open, set_mdl_select_lang_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_authentication.language !== undefined){
            onAuth_setDefaultLanguage(var_authentication);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_authentication]);

    useEffect(() => {
        if (var_params.code) {
            let params = JSON.parse(atob(var_params.code));
            if ('email' in params && 'password' in params) {
                set_login(params);
                login(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_params]);

    useEffect(() => {
        let var_emptyvalidation = true;

        if (!var_login.password || var_login.password === '') {
            var_emptyvalidation = false;
        }

        if (!var_login.email || var_login.email === '') {
            var_emptyvalidation = false;
        }
        set_ready(var_emptyvalidation);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_login]);


    //  async functions ------------------------------------------------------------------------------------------------


    async function login(e) {
        if (e) {
            e.preventDefault();
        }
        if (var_ready) {
            let var_validation = false;
            let var_validmessage = '';
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\+[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\.[\w-]+)*)([\w-]+(?:\+[\w-]+)*)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(var_login.email)) {
                var_validation = true;
                var_validmessage = 'Please provide a valid email address'
            }
            set_validationerrormessage(var_validmessage);
            set_validationerror(var_validation);

            if (var_validation === false) {
                set_error(false);
                set_processing(true);

                try {

                    //  check cognito authentication
                    let var_AWSauth = await Auth.signIn(var_login.email, var_login.password);
                    set_AWSuser(var_AWSauth);

                    if (var_AWSauth.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        set_login_step('NEWPASSWORD');
                    } else {

                        //  get cognito user details
                        let AWSuser = await Auth.currentUserInfo();
                        sessionStorage.setItem('username', AWSuser.username);

                        //  get TerraHub user details
                        let result = await API_get_authentication();

                        //check if account has been archived
                        if (result === 'NO ACTIVE ACCOUNT'){
                            set_error(true);
                            set_processing(false);
                            return;
                        }

                        result = result[0];
                        let authentication = {};
                        authentication['authenticated'] = true;
                        authentication['id'] = result.id;
                        authentication['individual_type'] = result.individual_type;
                        authentication['actingorganization'] = result.organization;
                        authentication['actingorganization_id'] = result.organization_id;
                        authentication['actingpermission_id'] = result.permission_id;
                        authentication['cognito_id'] = AWSuser.username;
                        authentication['firstname'] = result.firstname;
                        authentication['lastname'] = result.lastname;
                        authentication['userid'] = result.userid;
                        authentication['email'] = result.email;
                        authentication['organization'] = result.organization;
                        authentication['organization_id'] = result.organization_id;
                        authentication['permission_id'] = result.permission_id;
                        authentication['org_host'] = result.org_host;
                        authentication['language'] = 'en';
                        authentication['access'] = result.access;
                        sessionStorage.setItem('authentication', JSON.stringify(authentication));
                        set_authentication(authentication);
                    }
                } catch (error) {
                    if (error.message !== "Cannot read property 'username' of undefined") {
                        console.log(error.message);
                        set_error(true);
                        set_processing(false);
                    }
                }
            }
        }
    }

    async function onAuth_setDefaultLanguage(authentication) {
        authentication.language = config.language[authentication.language];
        sessionStorage.setItem('authentication', JSON.stringify(authentication));
        history.push('/individuals')
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_authentication() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('abcrc-admin-api', '/get-authentication');
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        if (event.target.name === 'email') {
            set_validationerror(false);
            event.target.value = event.target.value.toLowerCase().trim()
        }
        if (event.target.name === 'password') {
            event.target.value = event.target.value.trim()
        }
        const login = { ...var_login };
        login[event.target.name] = event.target.value;
        set_login(login);
        set_ready(true);
        set_error(false);
    }

    function onClick_forgotpassword() {
        history.push('/reset')
    }

    function onClick_togglePassword() {
        set_passwordShown(var_passwordShown ? false : true);
    }




    // RENDER APP ======================================================================================================
    return (
        <div className="crd_login">
            {var_login.inviteid &&
                <Message>
                    {t('Thank you for accepting the')} {var_login.name} {t('invite! Please login to complete the acceptance.')}
                </Message>
            }
            <Form onSubmit={login}>
                <Form.Group>
                    <Form.Field
                        className='email'
                        id='email'
                        name='email'
                        value={var_login.email || ''}
                        control={Input}
                        onChange={onChange_input}
                        label={t('EMAIL')}
                        placeholder={t('Email...')}
                        autoComplete='email'
                        readOnly={var_login.emaildisabled}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        className='password'
                        id='password'
                        name='password'
                        autoComplete='new-password'
                        value={var_login.password || ''}
                        control={Input}
                        type={!var_passwordShown ? 'password' : 'text'}
                        onChange={onChange_input}
                        label={t('PASSWORD')}
                        placeholder={t('Password...')}
                        icon={<Icon className="password_icon" name={!var_passwordShown ? 'eye' : 'eye slash'} link onClick={() => onClick_togglePassword()} size="large" />}
                    />
                </Form.Group>

                {var_error &&
                <div id="loginerror" className="message warning">
                    <div className="message_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/warning 60px (d91e18).svg" alt={t('warning icon')} /></div>
                    <div className="message_text_wrapper">
                        <div className="message_text cause">{t('We were unable to log you in.')}</div>
                        <div className="message_text resolution">{t('Please check your email and password and try again.')}</div>
                    </div>
                </div>
                }

                {var_validationerror &&
                    <div id="loginerror" className="message warning">
                        <div className="message_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/warning 60px (d91e18).svg" alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">{t(var_validationerrormessage)}</div>
                        </div>
                    </div>
                }


                {!var_processing ?
                    <Button type='submit' id="btn_login" className={"btn_primary " + (var_ready ? 'btn_active' : '')} onClick={login}>{t('LOGIN')}</Button>
                    :
                    <Button loading id="btn_login" className="btn_primary btn_active">{t('LOGIN')}</Button>
                }

                
            </Form>

            <div className="btn_forgotpassword" onClick={() => onClick_forgotpassword()}>{t('Forgot Password')}</div>

            {var_login.termsofservice &&
            <p className="toc">{t('By creating an account you are agreeing to these')} <a href={var_login.termsofservice} target="_blank" rel="noopener noreferrer">{t('Terms and Conditions')}</a></p>
            }

            {/***** MODAL: LOGIN *********************************************************************************/}

            <Modal id="mdl_select_language"
                   dimmer={'inverted'}
                   closeOnEscape={false}
                   closeOnDimmerClick={false}
                   open={var_mdl_select_lang_open}
                   onClose={() => set_mdl_select_lang_open(false)}>
            </Modal>

            {/***** END MODAL: LOGIN *****************************************************************************/}


        </div>
    )

}