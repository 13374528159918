/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./transactions.css";
import { useTranslation } from 'react-i18next';
import '../../i18n';

import CRD_TRANSACTIONS from "./crd_transactions/crd_transactions";


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const history = useHistory();



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if( sessionStorage.getItem('authentication')) {
            appProps.set_visibleframe(true);
            appProps.set_activemenu('/transactions');
        }else{
            history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            {(sessionStorage.getItem('authentication')) &&
            <div className="content">
                <div
                    className="content-topmenu">{t("Transactions")}
                </div>
                <div className="content-wrapper">

                    <CRD_TRANSACTIONS></CRD_TRANSACTIONS>

                </div>
            </div>
            }
        </>
    )
};
