/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from "react";
import { Message } from 'semantic-ui-react'
import { useHistory } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { useTranslation } from 'react-i18next';
import React from "react";
import auth from '../../libs/auth-lib';

import "./invite.css";


export default function(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation();
    const history = useHistory();
    const [var_invite, set_invite] = useState([]);
    const [var_invitestatus, set_invitestatus] = useState('');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        appProps.set_visibleframe(false);

        sessionStorage.clear();
        auth.clear();

        let urlstring = window.location.search.split('&')
        if(urlstring[0].substr(10)==='DECLINED'){
            updateDeclined(urlstring[1].substr(3))
        }else if(urlstring[0].substr(10)==='ACCEPT'){
            updateAccepted(urlstring[1].substr(3))
        }else if(urlstring[0].substr(10)!=='ACCEPT' && urlstring[0].substr(10)!=='DECLINED'){
            history.push("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function updateDeclined(inviteid){
        try {
            let invite = await API_put_invite_decline(inviteid);
            set_invite(invite);
            set_invitestatus('DECLINED');
        } catch (e) {
            if (e.response && e.response.data && e.response.data.message){
                set_invite(e.response.data);
                set_invitestatus('ERROR');
            } else {
                console.log(e);
            }
        }
    }

    async function updateAccepted(inviteid){
        try {
            let invite = await API_get_invitestatus(inviteid);
            // If user is logged in, log them out first
            if ((await Auth.currentUserInfo()) !== null) {
                await Auth.signOut();
                sessionStorage.clear();
                auth.clear();
            }
            sessionStorage.setItem('invitestatus',JSON.stringify({...invite, inviteid: inviteid, invitesource: 'INVITE'}));
            history.push("/login");
        } catch (e) {
            if (e.response && e.response.data && e.response.data.message){
                set_invite(e.response.data);
                set_invitestatus('ERROR');
            } else {
                console.log(e);
            }
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_invite_decline(inviteid){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('abcrc-admin-api', '/put-invite-decline/'+inviteid);
    }

    function API_get_invitestatus(inviteid){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('abcrc-admin-api', '/get-invitestatus/'+ inviteid);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function renderDeclined() {
        return (
            <div className="request">
                <Message>{t('You have declined the invitation from')} {var_invite.name}.  {t('Thank you for your response.')}</Message>
            </div>
        );
    }

    function renderMessage() {
        return (
            <div className="request">
                <Message className="warning">
                    <div className="message_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/warning 60px (d91e18).svg" alt={t('warning icon')} /></div>
                    <div className="message_text_wrapper">{t(var_invite.message)}</div>
                </Message>
            </div>
        );
    }

    // RENDER APP ======================================================================================================

    return (
        <div className="login_wrapper">
            {var_invitestatus === 'DECLINED'
                ? renderDeclined()
                : var_invitestatus === 'ERROR'
                ? renderMessage()
                : null
            }
        </div>
    );
}