/* eslint-disable import/no-anonymous-default-export */
import { API } from "aws-amplify";
import { Button, Form, Input } from 'semantic-ui-react'
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import './crd_request.css';



export default function({ set_reset_step }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const history = useHistory();

    const [ var_request, set_request ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_ready, set_ready ] = useState(false);
    const [var_error, set_error] = useState(false);
    const [var_validationerror, set_validationerror] = useState(false);
    const [var_validationerrormessage, set_validationerrormessage] = useState('');


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let var_emptyvalidation = true;
        if (!var_request.email || var_request.email === '') {
            var_emptyvalidation = false;
        }
        set_ready(var_emptyvalidation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_request]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function submit_request(){
        if(var_ready) {
            let var_validation = false;
            let var_validmessage = '';
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\+[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\.[\w-]+)*)([\w-]+(?:\+[\w-]+)*)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(var_request.email)) {
                var_validation = true;
                var_validmessage = 'Please provide a valid email address'
            }
            set_validationerrormessage(var_validmessage);
            set_validationerror(var_validation);
            if (var_validation === false) {
                set_error(false);
                set_processing(true);

                try {
                    let response = await API_post_passwordreset();
                    if (response === 'EMAIL NOT FOUND') {
                        set_error(true);
                    } else {
                        set_error(false);
                        set_reset_step('REQUESTSENT');
                    }
                } catch (e) {
                    console.log('error')

                    set_error(true);
                }

                set_processing(false)
            }
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_passwordreset(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('abcrc-admin-api', '/post-passwordreset',{ body: var_request });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event){
        const request = { ...var_request };
        set_validationerror(false);
        request[event.target.name] = event.target.value.toLowerCase().trim();
        set_request(request);
        set_ready(true);
        set_error(false);
    }


    function onClick_submit(){
        submit_request();
    }


    function onClick_backtologin(){
        history.push('/login')
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="message info">
                <div className="message_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/info 60px (4dcbd4).svg" alt={t('info icon')}/></div>
                <div className="message_text_wrapper">
                    <div className="message_text resolution">{t('To reset your password, enter the email address you use to login and click "Request Password Reset"')}</div>
                </div>
            </div>
            <Form>
                <Form.Group>
                    <Form.Field
                        className='email'
                        id='email'
                        name='email'
                        value={var_request.email || ''}
                        control={Input}
                        onChange={onChange_input}
                        label={t('EMAIL')}
                        placeholder={t('Email...')}
                    />
                </Form.Group>
            </Form>

            {var_error &&
            <div className="message warning">
                <div className="message_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/warning 60px (d91e18).svg" alt={t('warning icon')}/></div>
                <div className="message_text_wrapper">
                    <div className="message_text cause">{t('We were unable to find an account using that email address.')}</div>
                    <div className="message_text resolution">{t('Please check your email address and try again.')}</div>
                </div>
            </div>
            }
            {var_validationerror &&
                <div className="message warning">
                    <div className="message_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/warning 60px (d91e18).svg" alt={t('warning icon')} /></div>
                    <div className="message_text_wrapper">
                        <div className="message_text cause">{t(var_validationerrormessage)}</div>
                    </div>
                </div>
            }

            {!var_processing ?
                <Button id="btn_submitrequest" className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t('REQUEST PASSWORD RESET')}</Button>
                :
                <Button loading className="btn_primary btn_active">{t('REQUEST PASSWORD RESET')}</Button>
            }
            <div className="btn_backtologin" onClick={() => onClick_backtologin()}>{t('Back to login')}</div>
        </>
    )

}
